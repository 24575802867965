import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../components/button/Button';
import SiteLink from '../components/siteLink/SiteLink';
import TextInput from '../components/textInput/TextInput';
import usePasswordReset from '../hooks/usePasswordReset';
import FormsContainer from './FormsContainer';

const PasswordReset = () => {
	const initialFormValues = {
		email: '',
	};
	const validationFormSchema = Yup.object({
		email: Yup.string().required('Email is required.'),
	});
	const { getResetCode } = usePasswordReset();
	const navigate = useNavigate();
	const handleCodeRequest = async (values) => {
		const { email } = values;
		const codeDelivered = await getResetCode(email);
		if (codeDelivered) {
			navigate('/new-password', {
				replace: true,
				state: {
					userEmail: email,
				},
			});
		}
	};
	return (
		<section className='flex flex-col items-center'>
			<FormsContainer
				data={{
					formInitialValues: initialFormValues,
					formValidationSchema: validationFormSchema,
					handleFormSubmission: handleCodeRequest,
				}}>
				<TextInput
					name='email'
					placeholder='Enter your email'
					type='email'
					label='Email'
				/>
				<div className='flex flex-col items-center gap-y-4 lg:flex-row lg:justify-between py-4'>
					<Button data={{ label: 'Reset Password', type: 'submit' }} />
					<SiteLink
						data={{
							text: 'Login here',
							onClickHandler: () => navigate('/login', { replace: true }),
						}}
					/>
				</div>
			</FormsContainer>
		</section>
	);
};

export default PasswordReset;
