import { Amplify } from 'aws-amplify';
import { Route, Routes } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved, import/extensions
import awsconfig from './aws-exports';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/NavBar';
import RequireAuth from './components/requireAuth/RequireAuth';
import LoginForm from './forms/LoginForm';
import NewPassword from './forms/NewPassword';
import PasswordReset from './forms/PasswordReset';
import RegisterConfirmation from './forms/RegisterConfirmation';
import RegisterForm from './forms/RegisterForm';
import Homepage from './pages/homepage/Homepage';
import ManagePage from './pages/managePage/ManagePage';
import RequestPage from './pages/requestPage/RequestPage';

Amplify.configure(awsconfig);

function App() {
	return (
		<>
			<Navbar />
			<Routes>
				<Route
					path='/'
					element={<Homepage />}
				/>
				<Route
					path='login'
					element={<LoginForm />}
				/>
				<Route
					path='register'
					element={<RegisterForm />}
				/>
				<Route
					path='request-access'
					element={
						<RequireAuth redirectTo='/login'>
							<RequestPage />
						</RequireAuth>
					}
				/>
				<Route
					path='manage-requests'
					element={
						<RequireAuth redirectTo='/login'>
							<ManagePage />
						</RequireAuth>
					}
				/>
				<Route
					path='forgot-password'
					element={<PasswordReset />}
				/>
				<Route
					path='confirm-registration'
					element={<RegisterConfirmation />}
				/>
				<Route
					path='new-password'
					element={<NewPassword />}
				/>
				<Route
					path='*'
					element={<Homepage />}
				/>
			</Routes>
			<Footer />
		</>
	);
}

export default App;
