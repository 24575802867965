import { Form, Formik } from 'formik';

const FormsContainer = ({ children, data }) => {
	const { formInitialValues, formValidationSchema, handleFormSubmission } =
		data;
	return (
		<section className='w-2/3 lg:w-1/3 rounded-lg ring-2 p-5 ring-secondary'>
			<Formik
				initialValues={formInitialValues}
				validationSchema={formValidationSchema}
				onSubmit={handleFormSubmission}>
				<Form className='flex flex-col gap-y-2 py-2'>{children}</Form>
			</Formik>
		</section>
	);
};

export default FormsContainer;
