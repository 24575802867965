const AlertsContainer = ({ children, state }) => {
	const alarmState = () => {
		switch (state) {
			case 'ALARM':
				return 'border-danger';
			case 'OK':
				return 'border-success';
			default:
				return 'border-alert';
		}
	};
	return (
		<div className={`border-2 border-l-8 rounded p-2 ${alarmState()}`}>
			{children}
		</div>
	);
};

export default AlertsContainer;
