import { useEffect, useState } from 'react';

import Alert from '../../components/alert/Alert';
import AlertsContainer from '../../components/alertsContainer/AlertsContainer';
import { dateConverter, getRoleNamesList } from '../../helpers/helpers';
import dynamicTitle from '../../hooks/dynamicTitle';
import useAPI from '../../hooks/useAPI';
import useAuthContext from '../../hooks/useAuthContext';

function RequestPage() {
	const { user } = useAuthContext();
	const { name, lastLogin } = user;
	const lastAuth = dateConverter(lastLogin);
	dynamicTitle('Request Access | Break Glass');
	const [roleSelected, setRoleSelected] = useState();
	const [durationSelected, setDurationSelected] = useState();
	const [approverSelected, setApproverSelected] = useState();
	const [showRoles, setShowRoles] = useState(false);
	const [showHours, setShowHours] = useState(false);
	const [showApprovers, setShowApprovers] = useState(false);
	const [showSummary, setShowSummary] = useState(false);
	const [requested, setRequested] = useState(false);
	const { loading, data: roles } = useAPI(
		`${process.env.REACT_APP_API_URL}/roles`,
	);
	const roleNames = getRoleNamesList(roles);
	const [namesList, setNamesList] = useState(roleNames);
	useEffect(() => {
		setNamesList(roleNames);
	}, [loading]);
	const numberOfHours = [
		'1 Hour',
		'2 Hours',
		'3 Hours',
		'4 Hours',
		'5 Hours',
		'6 Hours',
		'7 Hours',
		'8 Hours',
	];
	const approversList = ['User 1', 'User 2', 'User 3'];
	const [approvers, setApprovers] = useState(approversList);

	const handleSelection = (e) => {
		const { value } = e.target;
		switch (value) {
			case 'select-role':
				setShowRoles(true);
				setShowHours(false);
				setShowApprovers(false);
				setShowSummary(false);
				break;
			case 'select-duration':
				setShowRoles(false);
				setShowHours(true);
				setShowApprovers(false);
				setShowSummary(false);
				break;
			case 'select-approver':
				setShowRoles(false);
				setShowHours(false);
				setShowApprovers(true);
				setShowSummary(false);
				break;
			case 'select-summary':
				setShowRoles(false);
				setShowHours(false);
				setShowApprovers(false);
				setShowSummary(true);
				break;
			default:
				break;
		}
	};
	const handleRoleSearch = (e) => {
		const { value } = e.target;
		const filteredNames = roleNames.filter((role) =>
			role.toLowerCase().includes(value.toLowerCase()),
		);
		setNamesList(filteredNames);
	};
	const handleApproverSearch = (e) => {
		const { value } = e.target;
		const filteredNames = approversList.filter((apr) =>
			apr.toLowerCase().includes(value.toLowerCase()),
		);
		setApprovers(filteredNames);
	};
	const handleRoleSelection = (e) => {
		const { value } = e.target;
		setRoleSelected(value);
	};

	const handleDurationSelection = (e) => {
		const { value } = e.target;
		setDurationSelected(value);
	};
	const handleApproverSelection = (e) => {
		const { value } = e.target;
		setApproverSelected(value);
	};

	return (
		<article>
			<header className='w-full text-center px-4'>
				<h1 className='text-2xl font-bold'>
					Hello {name}! Here you can create new requests.{' '}
				</h1>
				{lastLogin && (
					<p className='text-gray-300 my-4'>Last authenticated on {lastAuth}</p>
				)}
			</header>
			<section className='flex flex-col sm:flex-row gap-x-8 gap-y-4 px-4 justify-center my-8'>
				<button
					onClick={handleSelection}
					className={`font-bold text-xl bg-secondary p-4 rounded-lg ${
						roleSelected && 'bg-success'
					}`}
					value='select-role'>
					Select a role
				</button>
				<button
					onClick={handleSelection}
					className={`font-bold text-xl bg-secondary p-4 rounded-lg ${
						durationSelected && 'bg-success'
					}`}
					value='select-duration'>
					Select duration
				</button>
				<button
					onClick={handleSelection}
					className={`font-bold text-xl bg-secondary p-4 rounded-lg ${
						approverSelected && 'bg-success'
					}`}
					value='select-approver'>
					Select approver
				</button>
				<button
					onClick={handleSelection}
					className={`font-bold text-xl bg-secondary p-4 rounded-lg`}
					value='select-summary'>
					Show summary
				</button>
			</section>
			<section className='px-8 md:4/6 lg:w-3/6 mx-auto my-8'>
				{showRoles && !showHours && !showApprovers && !showSummary && (
					<>
						<h1>Select a role from the list</h1>
						<input
							name='role-search'
							type='text'
							placeholder='Type the role name'
							className='w-full rounded-lg border-0 bg-secondary text-alert focus:outline-none focus:ring-alert focus:border-alert invalid:bg-danger invalid:ring-danger disabled:bg-reburagrey disabled:text-primary'
							onChange={handleRoleSearch}
						/>
						<div className='flex flex-col gap-y-2 my-4 max-h-96 overflow-y-scroll border border-secondary'>
							{loading ? (
								<p className='ml-2 py-2 text-xl font-bold'>Loading roles</p>
							) : (
								namesList &&
								namesList.map((role, i) => (
									<button
										onClick={handleRoleSelection}
										value={role}
										key={i}
										className={`text-sm p-2 rounded-sm hover:cursor-pointer ${
											roleSelected === role ? 'bg-success' : null
										}`}>
										{role}
									</button>
								))
							)}
						</div>
					</>
				)}
				{showHours && !showRoles && !showApprovers && !showSummary && (
					<>
						{roleSelected ? (
							<h1>Select Duration</h1>
						) : (
							<Alert
								state='ALARM'
								text={'Please select a role'}
							/>
						)}
						<select
							className='w-full bg-secondary disabled:bg-reburagrey focus:ring-secondary focus:border-0 rounded-sm'
							disabled={!roleSelected}
							onChange={handleDurationSelection}
							value={durationSelected}>
							<option>Select duration for the access</option>
							{numberOfHours.map((hour, i) => (
								<option
									key={i}
									value={hour}
									className='appearance-none'>
									{hour}
								</option>
							))}
						</select>
					</>
				)}
				{showApprovers && !showRoles && !showHours && !showSummary && (
					<>
						{roleSelected && durationSelected ? (
							<h1>Select approver</h1>
						) : (
							<Alert
								state='ALARM'
								text={'Please select a role and duration'}
							/>
						)}
						<input
							disabled={!roleSelected}
							name='approver-search'
							type='text'
							placeholder='Type the approver name'
							className='w-full rounded-lg border-0 bg-secondary text-alert focus:outline-none focus:ring-alert focus:border-alert invalid:bg-danger invalid:ring-danger disabled:bg-reburagrey disabled:text-primary'
							onChange={handleApproverSearch}
						/>
						<div className='flex flex-col gap-y-2 my-4 max-h-96 overflow-y-scroll border border-secondary'>
							{loading ? (
								<p className='ml-2 py-2 text-xl font-bold'>Loading approvers</p>
							) : (
								approvers &&
								approvers.map((aprver, i) => (
									<button
										onClick={handleApproverSelection}
										disabled={!roleSelected}
										value={aprver}
										key={i}
										className={`text-sm p-2 rounded-sm hover:cursor-pointer ${
											approverSelected === aprver ? 'bg-success' : null
										}`}>
										{aprver}
									</button>
								))
							)}
						</div>
					</>
				)}
				{showSummary && !showHours && !showApprovers && !showRoles && (
					<section className='bg-secondary p-4 rounded-sm'>
						{roleSelected && durationSelected && approverSelected ? (
							<>
								{!requested && (
									<>
										<h1 className='text-center text-xl font-bold p-4'>
											Hey {name}, you are requesting {approverSelected} to grant
											you access to {roleSelected} for {durationSelected}.
										</h1>
										<div className='flex flex-col gap-y-4 my-4'>
											<button
												className='bg-success text-secondary p-4 rounded-md font-bold cusor-pointer'
												disabled={!roleSelected && !durationSelected}
												onClick={() => setRequested(true)}>
												Request access
											</button>
											<button
												className='bg-danger p-4 rounded-md font-bold cusor-pointer'
												onClick={() => {
													setRoleSelected(null);
													setDurationSelected(null);
													setApproverSelected(null);
												}}>
												Clear Selection
											</button>
										</div>
									</>
								)}
								{requested && (
									<AlertsContainer state='OK'>
										<Alert
											state='OK'
											text={`Your request has been submitted. ${approverSelected} will now be notified about your request. Once ${approverSelected} has actioned your request, you will receive a notification.`}
										/>
									</AlertsContainer>
								)}
							</>
						) : (
							<AlertsContainer state='ALARM'>
								<Alert
									state='ALARM'
									text='Select a role, duration and approver.'
								/>
							</AlertsContainer>
						)}
					</section>
				)}
			</section>
		</article>
	);
}

export default RequestPage;
