import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import dynamicTitle from '../../hooks/dynamicTitle';
import useAuthContext from '../../hooks/useAuthContext';

function HomePage() {
	const navigate = useNavigate();
	const { authIsReady } = useAuthContext();
	dynamicTitle('Break Glass');
	return (
		<main className='flex flex-col justify-center items-center gap-y-10 py-4'>
			<section>
				<h1 className='text-7xl font-bold'>Break Glass</h1>
			</section>
			{!authIsReady && (
				<section className='flex gap-x-4'>
					<Button
						data={{
							label: 'Login',
							type: 'button',
							onClickHandler: () => navigate('/login'),
						}}
					/>
					<Button
						data={{
							label: 'Register',
							type: 'button',
							onClickHandler: () => navigate('/register'),
						}}
					/>
				</section>
			)}
		</main>
	);
}

export default HomePage;
