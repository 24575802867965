import { Auth } from 'aws-amplify';
import { createContext, useEffect, useReducer } from 'react';

export const AuthContext = createContext();

const authReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'LOGIN':
			return { ...state, user: payload, authIsReady: true };
		case 'LOGOUT':
			return { ...state, user: payload, authIsReady: false };
		case 'AUTH_IS_READY':
			return { ...state, user: payload, authIsReady: true };
		default:
			return state;
	}
};
export const AuthProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		authIsReady: false,
	});
	useEffect(() => {
		const getCurrentUser = async () => {
			try {
				const res = await Auth.currentAuthenticatedUser({
					bypassCache: false,
				});
				const user = {
					...res.attributes,
					jwt: res.signInUserSession.idToken.jwtToken,
					jwtExp: res.signInUserSession.idToken.payload.exp,
					lastLogin: res.signInUserSession.idToken.payload.iat,
				};
				dispatch({
					type: 'AUTH_IS_READY',
					payload: user,
				});
			} catch (error) {
				throw new Error(error);
			}
		};
		getCurrentUser();
	}, []);

	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthContext.Provider>
	);
};
