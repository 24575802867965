import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuthContext from './useAuthContext';

const useLogout = () => {
	const [error, setError] = useState();
	const navigate = useNavigate();
	const { dispatch } = useAuthContext();
	const signOut = async () => {
		try {
			await Auth.signOut();
			dispatch({ type: 'LOGOUT', user: null });
			navigate('/');
		} catch (err) {
			setError(err.message);
		}
	};

	return { signOut, error };
};

export default useLogout;
