import { useState } from 'react';

import dynamicTitle from '../../hooks/dynamicTitle';

const ManagePage = () => {
	const [approved, setApproved] = useState(false);
	const [denied, setDenied] = useState(false);
	dynamicTitle('Manage Requests | Break Glass');
	return (
		<article>
			<main className='p-8 flex flex-col gap-y-4'>
				<h1 className='text-2xl font-bold'>
					Following requests require an action
				</h1>
				<div
					className={`px-8 py-2 flex justify-between items-center rounded-xl ${
						approved ? 'bg-success' : 'bg-alert'
					}`}>
					<h1 className='text-secondary font-bold text-xl'>
						Hanan is requesting access to S3 Production
					</h1>
					<div className='flex gap-x-4'>
						{!approved && (
							<>
								<button
									className='bg-success p-2 rounded-md'
									onClick={() => setApproved(true)}>
									Approve
								</button>
								<button className='bg-danger p-2 rounded-md'>Deny</button>
							</>
						)}
					</div>
				</div>
				<div
					className={`px-8 py-2 flex justify-between items-center rounded-xl ${
						denied ? 'bg-danger' : 'bg-alert'
					}`}>
					<h1 className='text-secondary font-bold text-xl'>
						Hanan is requesting access to EC2 Testing
					</h1>
					<div className='flex gap-x-4'>
						{!denied && (
							<>
								<button className='bg-success p-2 rounded-md'>Approve</button>
								<button
									className='bg-danger p-2 rounded-md'
									onClick={() => setDenied(true)}>
									Deny
								</button>
							</>
						)}
					</div>
				</div>
				<div className='bg-alert px-8 py-2 flex justify-between items-center rounded-xl'>
					<h1 className='text-secondary font-bold text-xl'>
						Hanan is requesting access to Rebura RDS Staging
					</h1>
					<div className='flex gap-x-4'>
						<button className='bg-success p-2 rounded-md'>Approve</button>
						<button className='bg-danger p-2 rounded-md'>Deny</button>
					</div>
				</div>
			</main>
			<section className='p-8 flex flex-col gap-y-4'>
				<h1 className='text-2xl font-bold'>Previously actioned requests</h1>
				<div className='bg-reburagrey w-full p-2 rounded-sm'>
					<p>Request from Hanan to EC2 Prod was denied by you</p>
				</div>
			</section>
		</article>
	);
};
export default ManagePage;
