import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicTitle from './dynamicTitle';

const usePasswordReset = () => {
	const [error, setError] = useState();
	const navigate = useNavigate();
	dynamicTitle('Reset Password | Break Glass');
	const getResetCode = async (username) => {
		try {
			const requestCode = await Auth.forgotPassword(username);
			if (!requestCode) {
				throw new Error('Unable to recover password, try again.');
			}
		} catch (err) {
			setError(err.message);
		}
	};
	const forgotPasswordSubmit = async (username, code, newpassword) => {
		try {
			const changePassword = await Auth.forgotPasswordSubmit(
				username,
				code,
				newpassword,
			);
			if (!changePassword) {
				throw new Error('Unable to change password, try again.');
			}
			navigate('/login');
		} catch (err) {
			setError(err.message);
		}
	};
	return { error, getResetCode, forgotPasswordSubmit };
};

export default usePasswordReset;
