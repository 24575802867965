import { useField } from 'formik';

import Alert from '../alert/Alert';
import AlertsContainer from '../alertsContainer/AlertsContainer';

const TextInput = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	return (
		<>
			<label>{label}</label>
			<input
				{...field}
				{...props}
				className='w-full rounded-lg border-0 bg-secondary text-alert focus:outline-none focus:ring-alert focus:border-alert invalid:bg-danger invalid:ring-danger disabled:bg-reburagrey disabled:text-primary'
			/>
			{meta.touched && meta.error ? (
				<AlertsContainer state='ALARM'>
					<Alert
						state='ALARM'
						text={meta.error}
					/>
				</AlertsContainer>
			) : null}
		</>
	);
};

export default TextInput;
