import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Alert from '../components/alert/Alert';
import AlertsContainer from '../components/alertsContainer/AlertsContainer';
import Button from '../components/button/Button';
import SiteLink from '../components/siteLink/SiteLink';
import TextInput from '../components/textInput/TextInput';
import useLogin from '../hooks/useLogin';
import FormsContainer from './FormsContainer';

const LoginForm = () => {
	const navigate = useNavigate();
	const { error, logIn } = useLogin();
	const initialFormValues = {
		email: '',
		password: '',
	};
	const formValidationSchema = Yup.object({
		email: Yup.string().required('Email is a required field'),
		password: Yup.string().required('Password is a required field'),
	});
	const handleLoginFormSubmission = async (values) => {
		const { email, password } = values;
		await logIn(email, password);
	};
	const handlePasswordReset = () => {
		navigate('/forgot-password');
	};
	return (
		<section className='flex flex-col items-center'>
			<FormsContainer
				data={{
					formInitialValues: initialFormValues,
					formValidationSchema,
					handleFormSubmission: handleLoginFormSubmission,
				}}>
				<TextInput
					name='email'
					placeholder='john@doe.com'
					type='email'
					label='Email'
				/>
				<TextInput
					name='password'
					placeholder='********'
					type='password'
					label='Password'
				/>
				{error && (
					<AlertsContainer state='ALARM'>
						<Alert
							state='ALARM'
							text={error}
						/>
					</AlertsContainer>
				)}
				<div className='flex flex-col gap-y-4 lg:flex-row lg:justify-between py-4'>
					<Button data={{ label: 'Login', type: 'submit' }} />
					<Button
						data={{
							label: 'Reset Password',
							type: 'button',
							onClickHandler: handlePasswordReset,
						}}
					/>
				</div>

				<SiteLink
					data={{
						text: 'New user? Register here!',
						value: null,
						path: '/register',
						type: 'link',
					}}
				/>
			</FormsContainer>
		</section>
	);
};

export default LoginForm;
