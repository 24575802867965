function Button({ data }) {
	const { label, type, onClickHandler, isPending } = data;
	return (
		<button
			type={type}
			onClick={onClickHandler}
			className={`bg-secondary px-8 py-1 rounded-xl text-grey font-md hover:cursor-pointer hover:bg-alert hover:text-secondary hover:font-bold
				${isPending ? 'disabled animate-pulse' : null}
			`}>
			{label}
		</button>
	);
}

export default Button;
