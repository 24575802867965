import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicTitle from './dynamicTitle';
import useAuthContext from './useAuthContext';

const useLogin = () => {
	const [error, setError] = useState();
	const { dispatch } = useAuthContext();
	const navigate = useNavigate();
	dynamicTitle('Login | Break Glass');
	const logIn = async (username, password) => {
		try {
			const res = await Auth.signIn(username, password);
			if (!res) {
				throw new Error("Couldn't login, please try again");
			}
			const user = {
				...res.attributes,
				jwt: res.signInUserSession.idToken.jwtToken,
				jwt_exp: res.signInUserSession.idToken.payload.exp,
				last_login: res.signInUserSession.idToken.payload.iat,
			};
			dispatch({ type: 'LOGIN', payload: user });
			navigate('/request-access');
		} catch (err) {
			setError(err.message);
		}
	};
	return { error, logIn };
};

export default useLogin;
