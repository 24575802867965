import { useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import logo from '../../assets/Reburalogo-white.png';
import useAuthContext from '../../hooks/useAuthContext';
import useLogout from '../../hooks/useLogout';

const Navbar = () => {
	const { signOut } = useLogout();
	const { authIsReady } = useAuthContext();
	const navigate = useNavigate();
	const mobileMenuRef = useRef();
	const toggleMobileMenu = () => {
		mobileMenuRef.current.classList.toggle('hidden');
	};
	const handleLogout = async () => {
		await signOut();
		toggleMobileMenu();
	};

	return (
		<nav className='flex justify-between items-center px-8 py-4'>
			<div onClick={() => navigate('/')}>
				<img
					src={logo}
					className='h-auto w-32 hover:cursor-pointer'
					alt='Rebura Official Logo'
				/>
			</div>
			<ul
				ref={mobileMenuRef}
				className='absolute top-0 left-0 bg-secondary border-2 border-l-0 rounded-r-lg border-primary min-h-screen pt-24 px-8 flex flex-col items-center gap-y-4
				hidden duration-500
				md:flex md:relative md:flex-row md:min-h-fit md:gap-y-0 md:gap-x-4 md:border-0 md:rounded-none md:bg-primary
				md:translate-x-0 md:pt-0
				'>
				{authIsReady && (
					<li
						onClick={toggleMobileMenu}
						className='hover:cursor-pointer hover:underline hover:underline-offset-8 hover:decoration-alert flex gap-x-1'>
						<NavLink to='/request-access'>Request Access</NavLink>
					</li>
				)}
				{authIsReady && (
					<li onClick={toggleMobileMenu}>
						<NavLink
							to='/manage-requests'
							className='hover:cursor-pointer hover:underline hover:underline-offset-8 hover:decoration-alert flex gap-x-1'>
							Manage Requests
						</NavLink>
					</li>
				)}
				{authIsReady && (
					<li onClick={handleLogout}>
						<NavLink
							to='/'
							className='hover:cursor-pointer hover:underline hover:underline-offset-8 hover:decoration-alert flex gap-x-1'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9'
								/>
							</svg>
							Logout
						</NavLink>
					</li>
				)}
				{!authIsReady && (
					<li onClick={toggleMobileMenu}>
						<NavLink
							to='/login'
							className='hover:cursor-pointer hover:underline hover:underline-offset-8 hover:decoration-alert flex gap-x-1'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75'
								/>
							</svg>
							Login
						</NavLink>
					</li>
				)}
			</ul>
			<div
				onClick={toggleMobileMenu}
				className='md:hidden'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					strokeWidth={1.5}
					stroke='currentColor'
					className='w-6 h-6 hover:cursor-pointer'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
					/>
				</svg>
			</div>
		</nav>
	);
};

export default Navbar;
