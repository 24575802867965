const Footer = () => {
	const currentYear = new Date().getFullYear();
	return (
		<footer className='text-center text-grey bg-primary py-2'>
			<p>&copy; {currentYear} - Owned by Rebura LTD.</p>
		</footer>
	);
};

export default Footer;
