import { Link } from 'react-router-dom';

const SiteLink = ({ data }) => {
	const { text, value, path, type, onClickHandler, isPending } = data;
	return (
		<div
			className={`hover:cursor-pointer hover:underline hover:underline-offset-4 hover:decoration-secondary ${
				isPending ? 'disabled animate-pulse' : null
			}`}>
			{type === 'link' ? (
				<li
					className='list-none text-center'
					value={value}>
					<Link to={path}>{text}</Link>
				</li>
			) : (
				<p onClick={onClickHandler}>{text}</p>
			)}
		</div>
	);
};

export default SiteLink;
