import { useState } from 'react';
import * as Yup from 'yup';

import Alert from '../components/alert/Alert';
import AlertsContainer from '../components/alertsContainer/AlertsContainer';
import Button from '../components/button/Button';
import SiteLink from '../components/siteLink/SiteLink';
import TextInput from '../components/textInput/TextInput';
import useSignUp from '../hooks/useSignUp';
import FormsContainer from './FormsContainer';

const RegisterForm = () => {
	const initialFormValues = {
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
	};
	const validationFormSchema = Yup.object({
		name: Yup.string().required('Name is a required field'),
		email: Yup.string().required('Email is a required field'),
		password: Yup.string()
			.required('Password is a required field')
			.matches(
				// eslint-disable-next-line no-useless-escape
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
			),
		confirmPassword: Yup.string()
			.required('Confirm Password is a required field')
			.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	});
	const { signUp, error } = useSignUp();
	const [isPending, setIsPending] = useState(false);
	const handleRegisterFormSubmission = async (values) => {
		const { name, email, password } = values;
		setIsPending(true);
		await signUp(email, password, name);
		setIsPending(false);
	};
	return (
		<section className='flex flex-col items-center'>
			<FormsContainer
				data={{
					formInitialValues: initialFormValues,
					formValidationSchema: validationFormSchema,
					handleFormSubmission: handleRegisterFormSubmission,
				}}>
				<TextInput
					name='name'
					placeholder='John Doe'
					type='text'
					label='Name'
				/>
				<TextInput
					name='email'
					placeholder='john@doe.com'
					type='email'
					label='Email'
				/>
				<TextInput
					name='password'
					placeholder='******'
					type='password'
					label='Password'
				/>
				<TextInput
					name='confirmPassword'
					placeholder='Same as password'
					type='password'
					label='Confirm Password'
				/>
				{error && (
					<AlertsContainer state='ALARM'>
						<Alert
							state='ALARM'
							text={error}
						/>
					</AlertsContainer>
				)}
				<>
					<div className='flex flex-col gap-y-4 lg:flex-row lg:justify-between py-4'>
						<Button data={{ label: 'Register', type: 'submit', isPending }} />
					</div>
					<SiteLink
						data={{
							text: 'Existing user? Login here!',
							value: null,
							path: '/login',
							type: 'link',
							isPending,
						}}
					/>
				</>
			</FormsContainer>
		</section>
	);
};

export default RegisterForm;
