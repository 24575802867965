export const passwordValidation = (password) => {
	const uppercaseRegExp = /(?=.*?[A-Z])/;
	const lowercaseRegExp = /(?=.*?[a-z])/;
	const digitsRegExp = /(?=.*?[0-9])/;
	const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
	const minLengthRegExp = /.{8,}/;
	if (
		uppercaseRegExp.test(password) ||
		lowercaseRegExp.test(password) ||
		digitsRegExp.test(password) ||
		specialCharRegExp.test(password) ||
		minLengthRegExp.test(password)
	) {
		return false;
	}
	return true;
};

export const dateConverter = (numbers) => {
	const date = new Date(numbers * 1000).toUTCString();
	return date;
};

export const getRoleNamesList = (data) => {
	const namesList = [];
	if (Array.isArray(data)) {
		data?.forEach((role) => {
			if (Object.prototype.hasOwnProperty.call(role, 'role_name')) {
				namesList.push(role.role_name);
			}
		});
	}
	return namesList;
};
