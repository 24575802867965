import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Alert from '../components/alert/Alert';
import AlertsContainer from '../components/alertsContainer/AlertsContainer';
import Button from '../components/button/Button';
import SiteLink from '../components/siteLink/SiteLink';
import TextInput from '../components/textInput/TextInput';
import usePasswordReset from '../hooks/usePasswordReset';
import FormsContainer from './FormsContainer';

const NewPassword = () => {
	const initialFormValues = { email: '', code: '', password: '' };
	const validationFormSchema = Yup.object({
		email: Yup.string().required('Email is a required field'),
		password: Yup.string()
			.required('Password is a required field')
			.matches(
				// eslint-disable-next-line no-useless-escape
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
			),
		code: Yup.string().required('Code is a required field'),
	});
	const { error, forgotPasswordSubmit } = usePasswordReset();
	const navigate = useNavigate();
	const location = useLocation();
	const { userEmail } = location.state;
	const handleNewPassword = async (values) => {
		const { email, code, password } = values;
		await forgotPasswordSubmit(email, code, password);
	};
	return (
		<section className='flex flex-col items-center'>
			<FormsContainer
				data={{
					formInitialValues: initialFormValues,
					formValidationSchema: validationFormSchema,
					handleFormSubmission: handleNewPassword,
				}}>
				<h1 className='border-b-2 border-secondary pb-2'>
					If any user with {userEmail} exists, an email with a verification code
					will be delivered to {userEmail}.
				</h1>
				<TextInput
					name='email'
					placeholder='john@doe.com'
					type='email'
					label='Email'
				/>
				<TextInput
					name='code'
					placeholder='135792'
					type='Text'
					label='Code'
				/>
				<TextInput
					name='password'
					placeholder='********'
					type='password'
					label='New Password'
				/>
				{error && (
					<AlertsContainer state='ALARM'>
						<Alert
							state='ALARM'
							text={error}
						/>
					</AlertsContainer>
				)}
				<div className='flex flex-col items-center gap-y-4 lg:flex-row lg:justify-between py-4'>
					<Button data={{ label: 'Confirm New Password', type: 'submit' }} />
					<SiteLink
						data={{
							text: 'Change email here!',
							onClickHandler: () =>
								navigate('/forgot-password', { replace: true }),
						}}
					/>
				</div>
			</FormsContainer>
		</section>
	);
};

export default NewPassword;
