import CrossIcon from '../../assets/CrossIcon';
import TickIcon from '../../assets/TickIcon';

const Alert = ({ state, text }) => {
	const alarmState = () => {
		switch (state) {
			case 'ALARM':
				return { style: 'text-danger', icon: <CrossIcon /> };
			case 'OK':
				return { style: 'text-success', icon: <TickIcon /> };
			default:
				return { style: 'text-white', icon: null };
		}
	};
	return (
		<div className='flex gap-x-2 items-center'>
			{alarmState().icon}
			<p className={`${alarmState().style}`}>{text}</p>
		</div>
	);
};

export default Alert;
