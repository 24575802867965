import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicTitle from './dynamicTitle';
import useAuthContext from './useAuthContext';

const useSignUp = () => {
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const { dispatch } = useAuthContext();
	dynamicTitle('Register | Break Glass');
	const signUp = async (email, password, name) => {
		try {
			const { user, userConfirmed } = await Auth.signUp({
				username: email,
				password,
				attributes: {
					name,
				},
				autoSignIn: {
					enabled: true,
				},
			});
			if (!user) {
				throw new Error('Registeration failed, please try again later.');
			}
			if (userConfirmed === false) {
				navigate('/confirm-registration', { replace: true });
			}
			dispatch({ type: 'LOGIN', payload: user });
		} catch (err) {
			setError(err.message);
		}
	};
	return { error, signUp };
};

export default useSignUp;
