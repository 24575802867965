import { Navigate } from 'react-router-dom';

import useAuthContext from '../../hooks/useAuthContext';

const RequireAuth = ({ children, redirectTo }) => {
	const { authIsReady } = useAuthContext();
	if (!authIsReady) {
		return <Navigate to={redirectTo} />;
	}
	return <>{children}</>;
};

export default RequireAuth;
