import { useState } from 'react';
import * as Yup from 'yup';

import Button from '../components/button/Button';
import SiteLink from '../components/siteLink/SiteLink';
import TextInput from '../components/textInput/TextInput';
import useAuthContext from '../hooks/useAuthContext';
import useSignUpConfirm from '../hooks/useSignUpConfirm';
import FormsContainer from './FormsContainer';

const RegisterConfirmation = () => {
	const authCtx = useAuthContext();
	const { username } = authCtx.user;

	const initialFormValues = {
		verificationCode: '',
	};
	const validationFormSchema = Yup.object({
		verificationCode: Yup.string().required('Code is a required field'),
	});

	const [isPending, setIsPending] = useState(false);
	const { confirmSignUp, resendConfirmationCode } = useSignUpConfirm();
	const handleRegisterFormSubmission = async (values) => {
		const { verificationCode } = values;
		setIsPending(true);
		await confirmSignUp(username, verificationCode);
		setIsPending(false);
	};
	const handleResendCode = async () => {
		setIsPending(true);
		await resendConfirmationCode(username);
		setIsPending(false);
	};
	return (
		<section className='flex flex-col items-center'>
			<FormsContainer
				data={{
					formInitialValues: initialFormValues,
					formValidationSchema: validationFormSchema,
					handleFormSubmission: handleRegisterFormSubmission,
				}}>
				<h1 className='border-b-2 pb-2 border-secondary'>
					A verification code has been sent to {username}, please use that code
					to verfiy your email address.
				</h1>
				<TextInput
					name='verificationCode'
					placeholder='135792'
					type='text'
					label='Verification Code'
				/>
				<div className='flex flex-col items-center gap-y-4 lg:flex-row lg:justify-between py-4'>
					<Button
						data={{ label: 'Confirm Email', type: 'submit', isPending }}
					/>
					<SiteLink
						data={{
							text: 'Email not received? Resend it!',
							onClickHandler: handleResendCode,
							isPending,
						}}
					/>
				</div>
			</FormsContainer>
		</section>
	);
};

export default RegisterConfirmation;
