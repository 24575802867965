import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicTitle from './dynamicTitle';

const useSignUpConfirm = () => {
	const [error, setError] = useState();
	const navigate = useNavigate();
	dynamicTitle('Verification | Break Glass');
	const confirmSignUp = async (username, code) => {
		try {
			const res = await Auth.confirmSignUp(username, code);
			if (res !== 'SUCCESS') {
				throw new Error("Couldn't verfiy your email, please try again.");
			}
			navigate('/login');
		} catch (err) {
			setError(err.message);
		}
	};

	const resendConfirmationCode = async (username) => {
		try {
			await Auth.resendSignUp(username);
		} catch (err) {
			setError(err.message);
		}
	};

	return { confirmSignUp, resendConfirmationCode, error };
};

export default useSignUpConfirm;
