import { useEffect, useState } from 'react';

const useAPI = (url) => {
	const [error, setError] = useState();
	const [loading, setLoading] = useState();
	const [data, setData] = useState();
	useEffect(() => {
		const fetchAPI = async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await fetch(url);
				const jsonRes = await res.json();
				const apiData = jsonRes;
				setData(apiData);
				setLoading(false);
			} catch (err) {
				setError(err);
				setLoading(false);
			}
		};
		fetchAPI();
	}, []);
	return { error, loading, data };
};

export default useAPI;
